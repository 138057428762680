<template>
  <div class="bgImage centerAll">
    <div>
      <a
        class="material-card-content centerAll"
        :href="$websiteUrl"
      >
        <img
          width="300px"
          src="@/assets/img/logoWhite.svg"
          alt="Boardgent logo"
        >
      </a>
      <card
        external-card="card container material-card card-form-container"
        internal-card="material-card-content"
        url-help=""
      >
        <section
          slot="header"
          class="modal-card-body"
        >
          <form
            action=""
            @submit.prevent="validateBeforeSubmit"
          >
            <div>
              <p
                style="margin-top: 12px;"
                class="title"
              >
                Change password
              </p>
              <b-notification
                :active="error != undefined"
                :closable="false"
                class="errorBanner"
                type="is-danger"
              >
                {{ error != undefined ? error.message : '' }}
              </b-notification>
              <b-field
                :type="errors.has('password') ? 'is-danger':''"
                label="New Password"
              >
                <b-input
                  v-model="password"
                  v-validate="'required|min:6'"
                  name="password"
                  type="password"
                  :password-reveal="password != '' && password != undefined"
                  placeholder="Your new password"
                />
              </b-field>
              <span
                v-show="errors.has('password')"
                class="help is-danger"
              >{{ errors.first('password') }}</span>
              <b-field
                :type="errors.has('password') ? 'is-danger':''"
                label="Confirm Password"
              >
                <b-input
                  v-model="confirmPassword"
                  v-validate="{is: password, required: true}"
                  name="confirmPassword"
                  type="password"
                  :password-reveal="confirmPassword != '' && confirmPassword != undefined"
                  placeholder="Repeat your new password"
                />
              </b-field>
              <span
                v-show="errors.has('confirmPassword')"
                class="help is-danger"
              >{{ errors.first('confirmPassword') }}</span>
            </div>
            <footer class="card-footer has-text-centered padding-card-footer">
              <button
                :disabled="(!password || !confirmPassword
                  || errors.has('password') || errors.has('confirmPassword'))"
                :class="{'is-loading': isLoading }"
                type="submit"
                class="button is-primary main-card-form-button"
              >
                Change password
              </button>
            </footer>
          </form>
        </section>
      </card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import card from '@/components/cross/Card.vue';
import toastMessage from '@/helpers/toastMessage';
import vtulEnums from '../../../cross/index';

const { managementAction } = vtulEnums.enum.authentication;

export default {
  name: 'ResetPassword',
  metaInfo: {
    title: 'Reset Password',
  },
  components: {
    card,
  },
  data() {
    return {
      password: undefined,
      confirmPassword: undefined,
      isLoading: false,
      error: undefined,
    };
  },
  methods: {
    ...mapActions('auth-management', { validateToken: 'create' }),
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.changePassword(this.password);
        }
      }).catch((error) => { throw new Error(error); });
    },
    changePassword(password) {
      this.isLoading = true;
      this.validateToken({
        action: managementAction.VALIDATE_RESET_PWD.value,
        password,
        token: this.$route.params.token,
      }).then(() => {
        toastMessage.showSuccess('Your password has been changed.');
        const thisComponent = this;
        setTimeout(() => {
          thisComponent.$router.push('/login');
        }, 2000);
      }).catch((err) => {
        this.isLoading = false;
        toastMessage.showError(err.message);
      });
    },
  },
};
</script>
